import styled, { css } from 'styled-components';
import { IoLogoWhatsapp } from 'react-icons/io';
import { TiPhone, TiLocation, TiMessages } from 'react-icons/ti';
import { MdMessage } from 'react-icons/md';
import { device } from '../../../theme';

export const FooterWrap = styled.footer`
  ${({ theme, reveal }) => `
    background-color: ${theme.colors.primary};
    color: ${theme.colors.light};
    margin-top: ${theme.spacings.xl}
    ${
      reveal === 'true' &&
      css`
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
      `
    };
`}
`;

export const FooterTop = styled.div`
  padding-top: 80px;
  padding-bottom: 40px;
  @media ${device.medium} {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  @media ${device.small} {
    padding-top: 40px;
    padding-bottom: 30px;
  }
`;

export const FooterBottom = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const Logo = styled.figure`
  margin-bottom: 30px;
`;

export const TextWidget = styled.div``;

export const FooterWidgetList = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const InfoWithIcon = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const iconStyle = css`
  margin-right: 8px;
  font-size: 20px;
  min-width: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const WhatsappIcon = styled(IoLogoWhatsapp)`
  ${iconStyle}
`;

export const PhoneIcon = styled(TiPhone)`
  ${iconStyle}
`;

export const AddressIcon = styled(TiLocation)`
  ${iconStyle}
`;

export const MessagesIcon = styled(MdMessage)`
  ${iconStyle}
`;

export const FooterWidget = styled.div`
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
  ${props =>
    props.responsive &&
    css`
        ${props =>
          props.responsive.large &&
          css`
            @media ${device.large} {
              margin-top: ${props => props.responsive.large.mt};
              margin-bottom: ${props => props.responsive.large.mb};
              margin-left: ${props => props.responsive.large.ml};
              margin-right: ${props => props.responsive.large.mr};
            }
          `}
        ${props =>
          props.responsive.medium &&
          css`
            @media ${device.medium} {
              margin-top: ${props => props.responsive.medium.mt};
              margin-bottom: ${props => props.responsive.medium.mb};
              margin-left: ${props => props.responsive.medium.ml};
              margin-right: ${props => props.responsive.medium.mr};
            }
          `}
        ${props =>
          props.responsive.small &&
          css`
            @media ${device.small} {
              margin-top: ${props => props.responsive.small.mt};
              margin-bottom: ${props => props.responsive.small.mb};
              margin-left: ${props => props.responsive.small.ml};
              margin-right: ${props => props.responsive.small.mr};
            }
          `}
        ${props =>
          props.responsive.xsmall &&
          css`
            @media ${device.xsmall} {
              margin-top: ${props => props.responsive.xsmall.mt};
              margin-bottom: ${props => props.responsive.xsmall.mb};
              margin-left: ${props => props.responsive.xsmall.ml};
              margin-right: ${props => props.responsive.xsmall.mr};
            }
          `}
    `}
`;
