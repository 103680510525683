import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import SEO from '../../components/seo';
import Header from '../../containers/Layout/Header';
import PageHeader from '../../components/pageheader';
import { Content } from '../../containers/CoursePage/styles';
import { Container, Description } from '../../components/CourseInfo/styles';

import Footer from '../../containers/Layout/Footer';

const TermsTemplate = ({ data, location, pageContext }) => {
  const { title, content } = data.contentfulTerms;

  return (
    <>
      <SEO title={title} />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title={title} />
      <Content>
        <Container>
          <Description>{parse(content.childContentfulRichText.html)}</Description>
        </Container>
      </Content>
      <Footer />
    </>
  );
};

export const courseQuery = graphql`
  query($slug: String!) {
    contentfulTerms(slug: { eq: $slug }) {
      title
      slug
      content {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;

TermsTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    contentfulTerms: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default TermsTemplate;
