import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { TiSocialInstagram, TiSocialFacebook, TiSocialYoutube } from 'react-icons/ti';
import { IoLogoWhatsapp } from 'react-icons/io';
import PropTypes from 'prop-types';
import Social from '../Social';
import SocialLink from '../Social/SocialLink';

const FollowSocialMedia = ({ hideWhatsapp }) => {
  const siteInfo = useStaticQuery(graphql`
    query SocialMediaData {
      site {
        siteMetadata {
          social {
            facebook
            instagram
            youtube
            whatsapp
          }
        }
      }
    }
  `);

  const {
    social: { facebook, instagram, youtube, whatsapp },
  } = siteInfo.site.siteMetadata;

  return (
    <>
      <Social space="8px" tooltip shape="rounded" varient="outlined">
        {whatsapp && !hideWhatsapp && (
          <SocialLink path={whatsapp} title="Whatsapp">
            <IoLogoWhatsapp />
          </SocialLink>
        )}
        {facebook && (
          <SocialLink path={facebook} title="Facebook">
            <TiSocialFacebook />
          </SocialLink>
        )}
        {instagram && (
          <SocialLink path={instagram} title="Instagram">
            <TiSocialInstagram />
          </SocialLink>
        )}
        {youtube && (
          <SocialLink path={youtube} title="Youtube">
            <TiSocialYoutube />
          </SocialLink>
        )}
      </Social>
    </>
  );
};

FollowSocialMedia.propTypes = {
  hideWhatsapp: PropTypes.bool,
};

FollowSocialMedia.defaultProps = {
  hideWhatsapp: false,
};

export default FollowSocialMedia;
