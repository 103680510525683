import styled, { css } from 'styled-components';
import { device } from '../../theme';

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxxl};
    margin-bottom: ${theme.spacings.lg};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    word-break: break-word;

    h1,
    h2,
    h3,
    h4 {
      margin: ${theme.spacings.xl} 0;
      color: ${theme.colors.primary};
    }

    img {
      display: block;
      margin: 0 auto ${theme.spacings.xl};
      max-width: 100%;
    }

    h1 {
      text-align: center;
      font-size: ${theme.fontSizes.h1};

      :not(:first-child) {
        margin-top: ${theme.spacings.xxl};
      }

      @media ${device.small} {
        margin-top: ${theme.spacings.xxl};
      }
    }

    a {
      color: ${props => props.theme.colors.secondary};
      text-decoration-line: underline;
      text-decoration-style: dotted;

      &:hover {
        color: ${props => props.theme.colors.primaryLight};
        text-decoration-line: none;
      }
    }

    ul {
      list-style-type: disc;
      padding-left: ${theme.spacings.xxl};
    }

    ul li p {
      margin-bottom: 0;
    }

    ul li:last-child {
      margin-bottom: ${theme.spacings.xl};
    }

    strong {
      color: ${theme.colors.secondary};
    }

    p {
      margin-bottom: ${theme.spacings.xl};
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.typography.lg};
    color: ${theme.colors.dark};
    text-align: justify;
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 300px;
    margin-right: ${theme.spacings.xl};

    @media ${device.small} {
      float: none;
      width: auto;
      margin: auto;
    }
  `}
`;
