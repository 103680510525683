import styled, { css } from 'styled-components';
import { device } from '../../theme';

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 66.666667%;
    margin: ${theme.spacings.xxl} auto;

    @media ${device.medium} {
      max-width: 100%;
      padding: 0 ${theme.spacings.xxl};
    }

    @media ${device.xsmall} {
      max-width: 100%;
      padding: 0 ${theme.spacings.lg};
    }
  `}
`;
